import { MdCheck } from "react-icons/md";
import moscot from "../../../../../../Components/moscot.png";
import { BsPersonCircle, BsInfoCircle } from "react-icons/bs";
import Rating from "@mui/material/Rating";
import "./QuoteTable.css";
import { setdata } from "../../../../../../helpers/helpers";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
} from "@mui/material";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import Skeleton from "@mui/material/Skeleton";

function QuoteTableRow(props) {
  const { row, isSelected, onSelect, type, service } = props;
  // console.log(isSelected + " " + service);
  return (
    <TableRow
      onClick={() => onSelect(row)}
      className={
        isSelected === row.comp_id && service === row.service_type
          ? "QuoteTableRowSelected"
          : "QuoteTableRow"
      }
    >
      <TableCell padding="none">
        <div className="QuoteTableDivCenter">
          {row?.img ? (
            <img src={row?.img} width="100" height="80" alt={row?.comp_name} />
          ) : (
            <span style={{ color: "#1976D2" }}>{row?.comp_name ?? "-"}</span>
          )}
        </div>
      </TableCell>
      <TableCell padding="none">
        <div className="QuoteTableDivCenter">{row?.service_type ?? "-"}</div>
      </TableCell>
      <TableCell padding="none">
        <div className="QuoteTableDivCenter">
          {row.response_from === "Hajex Bolt Account"
            ? "Via Bolt"
            : row.response_from}
          {row.response_from === "Hajex Bolt Account" ? (
            <img src={moscot} width="50" height="50" alt="" />
          ) : (
            <BsPersonCircle
              size={20}
              style={{ color: "#1976D2" }}
              className="ml-3"
            />
          )}
        </div>
      </TableCell>
      <TableCell padding="none">
        <div className="QuoteTableDivCenter">{row?.transit_days ?? "-"}</div>
      </TableCell>
      <TableCell padding="none">
        <div className="QuoteTableDivCenter">
          {setdata(() => row?.delivery_date, true)}
        </div>
      </TableCell>
      <TableCell padding="none">
        <div className="QuoteTableDivCenter">{row?.base_charge ?? "-"}</div>
      </TableCell>
      <TableCell padding="none">
        <div className="QuoteTableDivCenter">
          {row?.total ?? "-"}

          <Tooltip
            title={
              <div>
                Residential Charge : <b>{row?.residential_charge ?? "-"}</b>
                <br />
                Signature Charge : <b>{row?.signature_charge ?? "-"}</b>
                <br />
                Fuel Charge : <b>{row?.fuel_charge ?? "-"}</b>
                <br />
                Freight Charge : <b>{row?.freight_charge ?? "-"}</b>
                <br />
                {row?.tailgate_charge && (
                  <>
                    Tailgate Charge : <b>{row?.tailgate_charge ?? "-"}</b>
                    <br />
                  </>
                )}
                {row?.insurance_charge && (
                  <>
                    Insurance Charge : <b>{row?.insurance_charge ?? "-"}</b>
                    <br />
                  </>
                )}
                <b>Tax Charges</b>
                {row?.tax_charges?.map((tax) => (
                  <React.Fragment key={tax?.NameEn}>
                    <br />
                    {tax?.NameEn} : <b>{tax?.Charge ?? "-"}</b>
                  </React.Fragment>
                ))}
                {/* {row?.service_charge > 0 ? (
                  <>
                    <br />
                    Service Charge : <b>{row?.service_charge ?? "0"}</b>
                  </>
                ) : null} */}
              </div>
            }
            placement="top"
            style={{ marginLeft: "1px" }}
          >
            <IconButton>
              <BsInfoCircle size={"15"} />
            </IconButton>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell padding="none">
        <div className="QuoteTableDivCenter">
          <Rating
            name="half-rating-read"
            defaultValue={Number(row.rating)}
            precision={0.1}
            readOnly
            style={{ color: "#1976D2" }}
          />
        </div>
      </TableCell>
      <TableCell
        className={
          isSelected === row.comp_id && service === row.service_type
            ? "QuoteTabledataselected"
            : "QuoteTabledata2"
        }
      >
        <div className="QuoteTableDivCenter">
          {isSelected === row.comp_id && service === row.service_type ? (
            <MdCheck size={30} style={{ color: "#ffffff" }} />
          ) : (
            ""
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}

export default function QuoteTable(props) {
  const { data, selectedRows, onSelect, type, service, loader } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedData = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  return (
    <>
      {data?.length > 0 || loader ? (
        <>
          <TableContainer className="TableContainer">
            <Table className="QuoteTable">
              <TableHead>
                <TableRow style={{ borderBottom: "1px solid #ddd" }}>
                  <TableCell className="QuoteTableheaddata">
                    <div className="QuoteTableDivCenter">Service</div>
                  </TableCell>
                  <TableCell className="QuoteTableheaddata">
                    <div className="QuoteTableDivCenter">Service Type</div>
                  </TableCell>
                  <TableCell className="QuoteTableheaddata">
                    <div className="QuoteTableDivCenter">Response From</div>
                  </TableCell>
                  <TableCell className="QuoteTableheaddata">
                    <div className="QuoteTableDivCenter">Est. Transit Days</div>
                  </TableCell>
                  <TableCell className="QuoteTableheaddata">
                    <div className="QuoteTableDivCenter">
                      Est. Delivery Date
                    </div>
                  </TableCell>
                  <TableCell className="QuoteTableheaddata">
                    <div className="QuoteTableDivCenter">Base Charge</div>
                  </TableCell>
                  <TableCell className="QuoteTableheaddata">
                    <div className="QuoteTableDivCenter">Total</div>
                  </TableCell>
                  <TableCell className="QuoteTableheaddata">
                    <div className="QuoteTableDivCenter">Rating</div>
                  </TableCell>
                  <TableCell className="QuoteTabledata2"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedData.map((row) => (
                  <QuoteTableRow
                    key={row.comp_id + row.service_type + row.shipment_type}
                    row={row}
                    isSelected={selectedRows}
                    type={type}
                    onSelect={onSelect}
                    service={service}
                  />
                ))}
                {loader && (
                  <TableRow>
                    <TableCell
                      style={{ paddingTop: "8px", paddingBottom: "8px" }}
                    >
                      <Skeleton
                        variant="text"
                        sx={{
                          width: "100%",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{ paddingTop: "8px", paddingBottom: "8px" }}
                    >
                      <Skeleton
                        variant="text"
                        sx={{
                          width: "100%",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{ paddingTop: "8px", paddingBottom: "8px" }}
                    >
                      <Skeleton
                        variant="text"
                        sx={{
                          width: "100%",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{ paddingTop: "8px", paddingBottom: "8px" }}
                    >
                      <Skeleton
                        variant="text"
                        sx={{
                          width: "100%",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{ paddingTop: "8px", paddingBottom: "8px" }}
                    >
                      <Skeleton
                        variant="text"
                        sx={{
                          width: "100%",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{ paddingTop: "8px", paddingBottom: "8px" }}
                    >
                      <Skeleton
                        variant="text"
                        sx={{
                          width: "100%",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{ paddingTop: "8px", paddingBottom: "8px" }}
                    >
                      <Skeleton
                        variant="text"
                        sx={{
                          width: "100%",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{ paddingTop: "8px", paddingBottom: "8px" }}
                    >
                      <Skeleton
                        variant="text"
                        sx={{
                          width: "100%",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {!loader && (
              <div className="pagination__select_service">
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </div>
            )}
          </TableContainer>
        </>
      ) : (
        <div
          className="driver__screen_empty"
          style={{ minHeight: "20vh", width: "100%" }}
        >
          <div className="driver__scrnempt_content">
            <Sad className="driver__scrnempt_contenticon" />
            <div>No Quote to display!</div>
          </div>
        </div>
      )}
    </>
  );
}
